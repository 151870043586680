<template>
  <div>
    <b-modal
      v-model="isDonationModal"
      content-class="airspirit-modal"
      hide-footer
      hide-header
      header-class="airspirit-modal-header"
      body-class="newsletter-subscriber-modal-body pl-4 pr-4"
      size="md"
    >
        <div 
          @click="isDonationModal = false"
          class="airspirit-modal-header--close-icon mt-2"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="airspirit-modal-header--title mt-4"
          style=""
        >
          Donate to keep AIRspirit <br>
          Free and Accessible
        </div>

        <div
          v-if="!paymentDone"
        >
          <div class="pr-4 pl-4 mt-3 text-center" style="font-size: 0.9rem;">
            Help us to keep AIRspirit free and accessible for everyone support a reliable, independant source for aviation
            latest news, ads and invaluable other ressources.
          </div>

          <div
            class="pl-2 pr-2 mt-2"
          >

            <div 
              class="input-and-select-wrapper mt-2"
            >
              <input
                class="registration-input mt-2"
                :class="{'is-invalid-input': $v.donation.amount.$dirty && $v.donation.amount.$error}"
                type="number"
                placeholder="Amount"
                v-model="donation.amount"
                @input="$v.donation.amount.$touch()"
                :disabled="paymentOngoing"
                @keyup.enter="createOrder"
              />
              <select v-model="donation.currency" class="registration-input--inline-select" name="currency" :disabled="paymentOngoing">
                <option value="EUR">€ (euros)</option>
                <option value="USD">$ (US Dollars)</option>
              </select>
            </div>
            <button
              class="profile-delete-button mt-2 w-100"
              :disabled="$v.donation.$invalid"
              @click="createOrder"
              :style="paymentOngoing ? 'display: none;' : ''"
            >
              Confirm amount
            </button>

            <div class="form-field" id="card-field" :style="!paymentOngoing ? 'display: none;' : ''"></div>

            <div>
              <input
                v-if="paymentOngoing"  
                class="registration-input"
                :class="{'is-invalid-input': $v.cardInfo.cardholder.$dirty && $v.cardInfo.cardholder.$error}"
                type="text"
                maxlength="255"
                placeholder="Name on card"
                v-model="cardInfo.cardholder"
                @input="$v.cardInfo.cardholder.$touch()"
              />
            </div>


            <div>
              <input
                v-if="paymentOngoing"
                class="registration-input mt-4"
                :class="{'is-invalid-input': $v.cardInfo.email.$dirty && $v.cardInfo.email.$error}"
                type="email"
                maxlength="255"
                placeholder="Email address"
                v-model="cardInfo.email"
                @input="$v.cardInfo.email.$touch()"
              />
            </div>
            <button
              v-if="paymentOngoing"  
              class="profile-delete-button mt-2 w-100"
              :disabled="$v.cardInfo.$invalid"
              @click="pay"
            >
              Donate Now
            </button>
          </div>
        </div>

        <div
          class="mt-4 mb-4"
          v-else
        >
          <div>
            <strong>All of us at AIRspirit thank you deeply for your generous donation</strong> — 
            it empowers us to create new functionalities and offer an even better experience for the entire General Aviation community!
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import RevolutCheckout from '@revolut/checkout'

import { APIDonation } from '@/api/APIDonation'

const apiDonationService = new APIDonation()


export default {
  name: 'DonationModal',
  mixins: [
    validationMixin
  ],
  data() {
    return {
      currencies: [
        { value: 'USD', text: 'USD' },
        { value: 'EUR', text: 'EUR' },
        { value: 'GBP', text: 'GBP' },
      ],
      donation: {
        amount: null,
        currency: 'USD',
      },
      paymentDone: false,
      paymentOngoing: false,
      cardInfo: {
        email: null,
        cardholder: null,
      }
    };
  },
  computed : {
    isDonationModal: {
      get () {
        return this.$store.state.isDonationModal
      },
      set () {
        this.$store.commit('closeDonationModal')
      }
    },
  },
  validations: {
    donation: {
      amount: {
        required,
      },
      currency: {
        required,
      },
    },
    cardInfo: {
      email: {
        email,
        required
      },
      cardholder: {
        required,
      },
    }
  },

  methods: {
    async createOrder() {
      this.$store.commit('loadFromAPI') // Start loading
      const data = {
        amount: this.donation.amount * 100,
        currency: this.donation.currency,
      }
      const response = await apiDonationService.createOrder(data) // Await the API response
      console.log(response)

      const orderToken = response.data['token']
      const env = process.env.NODE_ENV === 'production' ? 'prod' : 'sandbox'
      const { createCardField } = await RevolutCheckout(orderToken, env) // Await RevolutCheckout
      
      // Initialize the card field
      this.cardField = createCardField({
        target: document.getElementById("card-field"),
        hidePostcodeField: true,
        styles: {
          default: {
            color: "black",
            "::placeholder": {
              color: "#888888",      /* Gray color for placeholder */
              "font-size": "16px",     /* Adjust font size */
              opacity: 0.4,          /* Full opacity */
              "font-weight": 100,
              "font-family": "Arial"
            }
          },
          autofilled: {
            color: "#fff"
          }
        },
        onSuccess: () => {
          // Handle successful payments
          const confirmDonationData = {
            amount: this.donation.amount,
            currency: this.donation.currency,
            name: this.cardInfo.cardholder,
            email: this.cardInfo.email,
          }
          apiDonationService.confirmDonation(confirmDonationData)
          this.paymentDone = true
        },
        onError: (error) => {
          // Handle payment errors
          window.alert(`Something went wrong. ${error}`)
        }
      })
      this.paymentOngoing = true
      this.$store.commit('endLoadingFromAPI') // Stop loading in the end
    },
    pay () {
      const data = {
        name: this.cardInfo.cardholder,
        email: this.cardInfo.email
      }
      console.log(data)
      this.cardField.submit(data)
      console.log('Payment submitted')
    }
  }
}
</script>

<style>
.donate--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  text-align: center;
  width: 300px;
}

.form-fieldset {
  padding: 1rem;
}

.form-fieldset + .form-fieldset {
  margin-top: 1rem;
}

.form-field {
  appearance: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 100;
  display: block;
  width: 100%;
  height: 42px;
  margin: 0.5rem 0;
  color: #fff;
  border: 1px solid #D9D9D8;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 10px;
  outline: none;
}

.form-field::placeholder {
  color: #666;
  font-weight: 100;
}

.form-field:invalid,
.form-field.rc-card-field--invalid {
  border-color: red;
}

.form-field:required:valid,
.form-field.rc-card-field--completed {
  border-color: green;
}

.form-field-error-message {
  color: red;
}
</style>