import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import registration from './modules/registration'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
  chatInitialMessage: '',
  isAPILoading: false,
  isLoginModal: false,
  isForgetPasswordModal: false,
  isGlobalErrorModal: false,
  isNewsletterSubscriberModal: false,
  isDonationModal: false,
}

const getters = {
  isAPILoading: state => !!state.isAPILoading,
  isForgetPasswordModal: state => !!state.isForgetPasswordModal,
  isLoginModal: state => !!state.isLoginModal,
  isNewsletterSubscriberModal: state => !!state.isNewsletterSubscriberModal,
  isDonationModal: state => !!state.isDonationModal,
}

const mutations = {
  closeForgetPasswordModal (state) {
    state.isForgetPasswordModal = false
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  openForgetPasswordModal (state) {
    state.isForgetPasswordModal = true
  },
  openGlobalErrorModal (state) {
    state.isGlobalErrorModal = true
  },
  openLoginModal (state) {
    state.isLoginModal = true
  },
  openNewsletterSubscriberModal (state) {
    state.isNewsletterSubscriberModal = true
  },
  openDonationModal (state) {
    state.isDonationModal = true
  },
  closeGlobalErrorModal (state) {
    state.isGlobalErrorModal = false
  },
  closeLoginModal (state) {
    state.isLoginModal = false
  },
  closeNewsletterSubscriberModal (state) {
    state.isNewsletterSubscriberModal = false
  },
  closeDonationModal (state) {
    state.isDonationModal = false
  },
  loadFromAPI (state) {
    state.isAPILoading = true
  },
  endLoadingFromAPI (state) {
    state.isAPILoading = false
  }
}


export default new Vuex.Store({
  modules: {
    user,
    auth,
    registration,
  },
  strict: debug,
  state,
  getters,
  mutations
})
